.wrap {
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height));
}

.card {
  width: 400px;
  padding: 10px;
  margin-bottom: 100px;
}

.logo {
  width: 300px;
  margin-bottom: 20px;
}
